import type { ComputedRef, MaybeRef } from 'vue';
import type { ImxPlatformObject } from '@models/ImxPlatformObject';

export default (
  platformObject: MaybeRef<ImxPlatformObject | null>
): ComputedRef<string> => {
  return computed(() => {
    return (
      toValue(platformObject)?.htmlHeadMetaDescription?.trim() ||
      toValue(platformObject)?.shortDescription?.trim() ||
      ''
    );
  });
};
