import type { ComputedRef, MaybeRef } from 'vue';
import type { ImxPlatformObject } from '@models/ImxPlatformObject';
import type { Nullable } from '@models/CustomUtilityTypes';

export default (
  platformObject: MaybeRef<Nullable<ImxPlatformObject>>
): ComputedRef<string> => {
  return computed(() => {
    if (isEmpty(platformObject)) {
      return '';
    }
    return (
      toValue(platformObject)?.htmlHeadTitle?.trim() ||
      toValue(platformObject)?.title?.trim() ||
      ''
    );
  });
};
